import { deleteFetcher, getFetcher, postFetcher, putFetcher } from './auth'

export const getTagDetails = async (tagId, token) => {
    if (!tagId) return {}

    try {
        const data = await getFetcher(`catalog-api/tag/${tagId}`, token)
        return data
    } catch (error) {
        console.log('tag details error', error)
        throw error
    }
}

export const getUserProfileData = async (request, token = null) => {
    const { userId } = request
    delete request.userId
    const queryString = new URLSearchParams(request).toString()

    try {
        const data = await getFetcher(
            `user-profile/${userId}?${queryString}`,
            token
        )
        return data
    } catch (err) {
        return err
    }
}

export const getUserProfileDataWithUsername = async (
    username,
    token = null
) => {
    try {
        // const request = {
        //     page: 1,
        //     limit: 10,
        //     includeEntities: `["userActions", "user", "live"]`,
        //     aggregations: 'listeningTime|followers|followees',
        // }
        // const queryString = new URLSearchParams(request).toString()
        // const url = `user-api/user/username/${username}?${queryString}`

        const data = await getFetcher(
            `user-api/user/username/${username}`,
            token
        )
        return data
    } catch (err) {
        return err
    }
}

export const getPlaylist = async (playlistId, token) => {
    if (!playlistId) return {}

    try {
        const data = await getFetcher(
            `playlist-api/playlist/${playlistId}`,
            token
        )
        return data
    } catch (error) {
        return error
    }
}

// export const getLiveStreamDetails = async (livestreamId) => {
//     // Check if the livestreamId exists
//     if (!livestreamId) return {}

//     try {
//         const URL = `/live-api/room/${livestreamId}?includeEntities=["roomParticipants"]`
//         const data = await makeRequest(URL)
//         return data.data
//     } catch (error) {
//         return error
//     }
// }

export const getLiveStreamDetails = async (livestreamId, token) => {
    if (!livestreamId) return {}

    try {
        const data = await getFetcher(
            `live-api/room/${livestreamId}?includeEntities=["roomParticipants"]`,
            token
        )

        return data
    } catch (error) {
        console.log('live stream details error', error)
        return error
    }
}

export const getContents = async (page, limit, token) => {
    try {
        const data = await getFetcher(
            `catalog-api/content?includeEntities["active"]&page=${page}&limit=${limit}`,
            token
        )

        return data
    } catch (err) {
        return err
    }
}

export const getSitemapContent = async (page, limit, token) => {
    try {
        const data = await getFetcher(
            `catalog-api/content/sitemap?page=${page}&limit=${limit}`,
            token
        )

        return data
    } catch (err) {
        return err
    }
}

export const getCatalogs = async (category, page, limit, token) => {
    try {
        const data = await getFetcher(
            `catalog-api/catalog/category/${category}?includeEntities["active"]&page=${page}&limit=${limit}`,
            token
        )

        return data
    } catch (err) {
        return err
    }
}

export const checkUserNameValidity = async (value) => {
    const token = localStorage.getItem('authToken')

    const data = await getFetcher(
        `user-api/user/username/${value}/validate`,
        token
    )

    return data
}

export const getAddressList = async (value) => {
    const token = localStorage.getItem('authToken')

    const data = await getFetcher(
        `user-api/location?q=${value}&limit=20&page=1`,
        token
    )

    return data
}

export const registerUser = async (body) => {
    const token = localStorage.getItem('authToken')

    try {
        const data = await putFetcher({
            url: `user-api/user`,
            body,
            header: { authorization: `Bearer ${token}` },
        })

        return data
    } catch (err) {
        return err
    }
}

export const uploadProfileImage = async (fd, userToken = null) => {
    const token = userToken ? userToken : localStorage.getItem('authToken')

    try {
        const data = await putFetcher({
            url: `user-api/user`,
            body: fd,
            header: { authorization: `Bearer ${token}` },
        })

        return data
    } catch (err) {
        return err
    }
}

export const getUserFollowData = async (request, token = null) => {
    const { action } = request
    delete request.action
    const queryString = new URLSearchParams(request).toString()

    try {
        const data = await getFetcher(
            `community-api/v1/user-action/${action}/user?${queryString}`,
            token
        )
        return data
    } catch (err) {
        return err
    }
}

export const subscribeToCatalog = async ({
    catalogId,
    token,
    userId,
    subscribed,
}) => {
    try {
        const data = await postFetcher({
            url: `community-api/v1/user-action`,
            body: {
                action: 'follow',
                actionValue: subscribed ? 0 : 1,
                entityId: catalogId,
                entitySubType: 'podcast',
                entityType: 'catalog',
                userId,
            },
            header: { authorization: `Bearer ${token}` },
        })

        return data
    } catch (err) {
        return err
    }
}

export const editUserDetails = async (body, token) => {
    try {
        const data = await putFetcher({
            url: `user-api/user`,
            body,
            header: { authorization: `Bearer ${token}` },
        })
        return data
    } catch (err) {
        return err
    }
}

export const userLoginWithOtp = async (body, token) => {
    try {
        const data = await postFetcher({
            url: `user-api/user/login`,
            body,
            header: { authorization: `Bearer ${token}` },
        })

        return data
    } catch (error) {
        console.log('login api error', error)
    }
}

export const fetchSegmentDetail = async (request, token) => {
    const segmentId = request.segmentId
    delete request.segmentId

    const queryString = new URLSearchParams(request).toString()
    try {
        const data = await getFetcher(
            `segment-api/segment/${segmentId}?${queryString}`,
            token
        )
        return data
    } catch (err) {
        console.log('error',err);
        return false
    }
}

export const getOTPAPI = async (request, token) => {
    try {
        const specialCharacters = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi
        request.mobileNumber = request.mobileNumber.replace(
            specialCharacters,
            ''
        )
        const data = await postFetcher({
            url: `user-api/auth/otp`,
            body: request,
            header: { authorization: `Bearer ${token}` },
        })
        return data
    } catch (error) {
        console.log('otp api error', error)
    }
}

export const getRecentPlayedData = async (request, token = null) => {
    const queryString = new URLSearchParams(request).toString()

    try {
        const data = await getFetcher(
            `content-playback-api/v1/content-playback/recent?${queryString}`,
            token
        )
        return data
    } catch (err) {
        return err
    }
}

export const getCollectionData = async (request, token = null) => {
    const queryString = new URLSearchParams(request).toString()

    try {
        const data = await getFetcher(
            `community-api/v1/user-actions/collection?${queryString}`,
            token
        )
        return data
    } catch (err) {
        return err
    }
}

export const getHistoryData = async (request, token = null) => {
    const queryString = new URLSearchParams(request).toString()

    try {
        const data = await getFetcher(
            `user-history-api/v1/history/contentPause?${queryString}`,
            token
        )
        return data
    } catch (err) {
        return err
    }
}

export const deleteHistoryData = async (token = null) => {
    try {
        const data = await deleteFetcher({
            url: `user-history-api/v1/history/contentPause`,
            header: { authorization: `Bearer ${token}` },
        })
        return data
    } catch (err) {
        return err
    }
}

export const getThemePageTitle = async (themePageId, token) => {
    try {
        const data = await getFetcher(
            `segment-api/page/name/${themePageId}`,
            token
        )
        return data
    } catch (err) {
        return err
    }
}

export const getCampaignData = async (campaignId, token = null) => {
    if (!campaignId) return {}

    try {
        const data = await getFetcher(
            `campaign-api/campaigns/${campaignId}`,
            token
        )
        return data.data
    } catch (err) {
        return err
    }
}

export const getRewardData = async (campaignId, token = null) => {
    if (!campaignId) return {}
    try {
        const data = await getFetcher(
            `campaign-api/campaigns/${campaignId}/rewards`,
            token
        )
        return data.data
    } catch (err) {
        return err
    }
}

export const getGiftsData = async (campaignId, token = null) => {
    if (!campaignId) return {}
    try {
        const data = await getFetcher(
            `campaign-api/campaigns/${campaignId}/gifts?page=1&limit=100`,
            token
        )
        return data.data
    } catch (err) {
        return err
    }
}

export const getTermsConditionsData = async (campaignId, token = null) => {
    if (!campaignId) return {}
    try {
        const data = await getFetcher(
            `campaign-api/campaigns/${campaignId}/terms-and-conditions`,
            token
        )
        return data.data
    } catch (err) {
        return err
    }
}

export const getLeaderboardData = async (campaignId, request, token = null) => {
    if (!campaignId) return {}
    const queryString = new URLSearchParams(request).toString()

    try {
        const data = await getFetcher(
            `campaign-api/campaigns/${campaignId}/leaderboard?${queryString}`,
            token
        )
        return data.data
    } catch (err) {
        return err
    }
}

export const joinParticipantCampaign = async (
    campaignId,
    request,
    token = null
) => {
    if (!campaignId) return {}

    try {
        const data = await postFetcher({
            url: `campaign-api/campaigns/${campaignId}/participants`,
            body: request,
            header: { authorization: `Bearer ${token}` },
        })
        return data
    } catch (err) {
        return err
    }
}

export const getParticipantStatusData = async (
    campaignId,
    participantId,
    token = null
) => {
    if (!campaignId) return {}

    try {
        const data = await getFetcher(
            `campaign-api/campaigns/${campaignId}/participants/${participantId}`,
            token
        )
        return data.data
    } catch (err) {
        return err
    }
}

export const getCampaignListData = async (request, token = null) => {
    const queryString = new URLSearchParams(request).toString()
    try {
        const data = await getFetcher(
            `campaign-api/campaigns?${queryString}`,
            token
        )
        return data
    } catch (err) {
        return err
    }
}

export const getCoinPackages = async (token) => {
    try {
        const data = await getFetcher(`assets-api/coin-package`, token, {
            platform: 'WEB',
            ID: 'ID',
        })

        return data
    } catch (error) {
        return error
    }
}

export const getUserWallet = async (token) => {
    try {
        const data = await getFetcher(`accounting-api/wallet`, token)

        return data
    } catch (error) {
        return error
    }
}

export const getSubscriptionCatalogList = async (
    subscriptionId,
    request,
    token
) => {
    if (!subscriptionId) return {}
    const queryString = new URLSearchParams(request).toString()
    try {
        const subscriptionCatalogAPIURL = `subscription-api/subscription-packages/${subscriptionId}/catalogs?${queryString}`
        const data = await getFetcher(subscriptionCatalogAPIURL, token)
        return data
    } catch (error) {
        return error
    }
}

export const getProgrammaticLandingPageDetails = async (genreName, token) => {
    if (!genreName) return {}

    try {
        const data = await getFetcher(
            `programmatic-landing-page/${genreName}`,
            token,
            {
                platform: 'web',
            }
        )

        return data
    } catch (error) {
        return error
    }
}

export const fetchGenreSegmentDetail = async ({
    genreName,
    segmentId,
    page,
    limit,
    token,
}) => {
    const url = `programmatic-landing-page/${genreName}/segment/${segmentId}?page=${page}&limit=${limit}`

    try {
        const data = await getFetcher(url, token)
        return data
    } catch (error) {
        console.log('genre segment detail error', error)
        return error
    }
}

export const initializeOthersPlaylistAPI = async (playlistId, token) => {
    try {
        const data = await getFetcher(
            `playlist-api/playlist/${playlistId}`,
            token
        )

        return data.data
    } catch (error) {
        console.log('playlist api error', error)
    }
}

export const getSearchResultAPI = async (request, token = null) => {
    const queryString = new URLSearchParams(request).toString()

    try {
        const data = await getFetcher(`search-api/search?${queryString}`, token)

        return data
    } catch (error) {
        console.log('search result api error', error)
    }
}

export const getCoinPackageID = async (orderId, token) => {
    try {
        const data = await getFetcher(
            `accounting-api/transaction/payment-init/${orderId}`,
            token
        )

        return data
    } catch (error) {
        return error
    }
}

export const getMidtransToken = async ({
    coinPackageId,
    token,
    subscriptionSelected,
    contentId,
}) => {
    try {
        const data = await postFetcher({
            url: `accounting-api/transaction/payment-init`,
            body: {
                entityType: !subscriptionSelected ? 'content' : 'coin',
                type: !subscriptionSelected ? 'purchase' : 'topup',
                entityId: !subscriptionSelected ? contentId : coinPackageId,
                paymentGateway: 'midtrans',
            },
            header: { authorization: `Bearer ${token}` },
        })

        return data
    } catch (error) {
        return error
    }
}

export const saveTransactionData = async (body, token) => {
    try {
        const data = await postFetcher({
            url: `accounting-api/transaction/buy`,
            body,
            header: { authorization: `Bearer ${token}` },
        })

        return data
    } catch (error) {
        return error
    }
}

export const initializeContentPlayPauseAPI = async (request, token) => {
    try {
        const data = await getFetcher(
            `content-playback-api/v1/content-playback`,
            token
        )

        return data.data
    } catch (error) {
        console.log('content play / pause error', error)
        return error
    }
}

export const initializeLiveroomTokenAPI = async (livestreamId, token) => {
    try {
        const data = await getFetcher(
            `live-api/room/${livestreamId}/token`,
            token
        )

        return data.data
    } catch (error) {
        console.log('live room token api error', error)
        return error
    }
}

export const validateFirebaseAccess = async (token) => {
    try {
        if (!token) return {}

        const data = await getFetcher(`user-api/v2/auth/token/firebase`, token)

        return data.data
    } catch (err) {
        return err
    }
}

export const redeemVoucher = async ({ livestreamId, voucherCode, token }) => {
    try {
        const data = await postFetcher({
            url: `live-api/room/${livestreamId}/vouchers/${voucherCode}/redeem`,
            header: { authorization: `Bearer ${token}` },
        })
        return data
    } catch (error) {
        console.log('error: reddeem voucher', error)
        return error
    }
}

export const getSubscriptionList = async (request, token) => {
    const queryString = new URLSearchParams(request).toString()
    try {
        const subscriptionCatalogAPIURL = `subscription-api/subscription-packages?${queryString}`
        const data = await getFetcher(subscriptionCatalogAPIURL, token)
        return data
    } catch (error) {
        console.log('subscription list api error', error)
        return error
    }
}

// get user subscription

export const getUserSubscription = async (request, token) => {
    // Check if the subscriptionId exists
    const queryString = new URLSearchParams(request).toString()
    try {
        const userSubscriptionAPIURL = `subscription-api/user-subscription?${queryString}`
        const data = await getFetcher(userSubscriptionAPIURL, token)
        return data
    } catch (error) {
        console.log('user subscription error', error)
        return error
    }
}

export const getWalletTransaction = async (request, token) => {
    const queryString = new URLSearchParams(request).toString()
    try {
        const walletTransactionAPIURL = `accounting-api/wallet-transaction?${queryString}`
        const data = await getFetcher(walletTransactionAPIURL, token)
        return data
    } catch (error) {
        console.log('wallet transaction api error', error)
        return error
    }
}

export const getWalletTransactionById = async (id, token) => {
    try {
        const walletTransactionAPIURL = `accounting-api/wallet-transaction/${id}`
        const data = await getFetcher(walletTransactionAPIURL, token)
        return data
    } catch (error) {
        console.log('wallet transaction api error', error)
        return error
    }
}

export const getSelectedUsersOfLiveroom = async (
    livestreamId,
    userIds,
    token
) => {
    if (!livestreamId) return {}

    try {
        const data = await getFetcher(
            `/live-api/room/${livestreamId}/participant?userIds=${JSON.stringify(
                userIds
            )}`,
            token
        )

        return data.data
    } catch (error) {
        console.log('live api room error', error)
        return error
    }
}

export const kuboVoucher = async (body, token) => {
    try {
        const data = await postFetcher({
            url: `accounting-api/transaction/purchase`,
            body,
            header: { authorization: `Bearer ${token}` },
        })

        return data
    } catch (error) {
        return error
    }
}

export const carLogin = async (body, token) => {
    try {
        const data = await postFetcher({
            url: `user-api/user/add-device`,
            body,
            header: { authorization: `Bearer ${token}` },
        })

        return data
    } catch (error) {
        return error
    }
}
